import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cp-main-inner__wrapper" }
const _hoisted_2 = { class: "cp-title-page__template" }
const _hoisted_3 = { class: "cp-uppercase" }
const _hoisted_4 = { class: "cp-page-sub-title cp-page-sub-title-template" }
const _hoisted_5 = { class: "cp-badges-container" }
const _hoisted_6 = {
  key: 0,
  class: "cp-badge cp-badge_test"
}
const _hoisted_7 = { class: "cp-form__wrapper cp-form__wrapper__template" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_title_return = _resolveComponent("title-return")!
  const _component_loading = _resolveComponent("loading")!
  const _component_form_block = _resolveComponent("form-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_title_return, {
      class: "cp-margin__tb-sx",
      onClick: _ctx.returnBefore
    }, {
      "icon-badge": _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.isTest)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Test"))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.titlePage), 1),
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_icon_font, {
              class: "cp-title-page__icon cp-title-page-template__icon",
              size: "19",
              icon: "clients"
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.clientName), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_loading, { key: 0 }))
        : (_openBlock(), _createBlock(_component_form_block, {
            key: 1,
            "type-action": "edit",
            onReturnBefore: _ctx.returnBefore
          }, null, 8, ["onReturnBefore"]))
    ])
  ]))
}